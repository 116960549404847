<template>
  <div class="home-about-us">
    <div class="flex-cover wrap">
      <div class="left">
        <h2>About us</h2>
        <h1>株式会社グレイトヘルプについて</h1>
      </div>
      <div class="right">
        <p>
          株式会社グレイトヘルプは、ウェブを主としたマーケティング・媒体制作による「売上アップ」と、業務のシステム化による「コスト削減」を強みにしたIT企業です。コンサルティング、ホームページやネットショプの制作、インターネット広告やSNSの運用、SaaS（システムの販売）などを通して、事業主様の業績向上を支援しています。
        </p>
      </div>
    </div>
    <div class="pt-200 pb-150 wrap">
      <h2>Our Business</h2>
      <h1>事業内容</h1>
    </div>

    <div class="herf-wrap">
      <div class="left">
        <h3>ウェブマーケティング<br />サポート事業</h3>
        <p>
          ホームページ制作、ネットショップ制作、システム開発、SEO対策、インターネット広告運用、SNS運用、DTP（紙媒体のデザイン）などを通して、企業・店舗・通販などのマーケティングを支援しています。高い技術力×幅広い技術力により、業績向上という「結果」を提供しています。
        </p>
      </div>
      <img
        alt="ホームページ制作なら株式会社グレイトヘルプ"
        src="@/assets/GH-corporate-001.png"
        class="pc-only right"
      />
      <img
        alt="ホームページ制作なら株式会社グレイトヘルプ"
        src="@/assets/GH-corporate-001sp.png"
        class="sp-only right"
      />
    </div>

    <a
      href="https://greathelp.co.jp/hp/"
      target="_blank"
      rel="noopener noreferrer"
      class="link-button"
      ><p>詳細を見る</p></a
    >

    <div class="pt-150 wrap flex-cover row-3">
      <div class="left">
        <h3>SaaS事業</h3>
        <p style="font-size:16px">
          アドアフィリエイトの必須ツール「アドタスカル」を開発・販売。アフィリエイトならではの広告媒体管理画面とASP管理画面との成果の乖離、Cookieによるトラッキング規制の問題を解決できます。連携ASP数90社超。Google広告、Yahoo!広告、Meta広告、Microsoft広告、TikTok広告と連携。
        </p>
      </div>
      <img
        alt="アドタスカル"
        src="@/assets/GH-corporate-002-new.png"
        class="pc-only right"
      />
      <img
        alt="アドタスカル"
        src="@/assets/GH-corporate-002sp-new.jpg"
        class="sp-only right"
      />
    </div>

        <a
      href="https://adtasukaru.com/"
      target="_blank"
      rel="noopener noreferrer"
      class="link-button"
      ><p>詳細を見る</p></a
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "home-top-class-website",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.flex-cover {
  .left {
    width: 30%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .right {
    width: 65%;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 20px;
    }
  }
}
.herf-wrap {
  padding-left: calc(calc(100vw - 1150px) * 0.49);
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    flex-direction: column;
  }
  .left {
    width: 575px;
    @media screen and (max-width: 767px) {
      padding-right: 20px;
      width: 100%;
      h3 {
        text-align: center;
      }
    }
  }
  .right {
    width: 55%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }
}
.link-button {
  margin-top: 50px;
}

.row-3 {
  .left {
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
      h3 {
        text-align: center;
      }
    }
  }
  .right {
    width: 45%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }
}
.pt-200 {
  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
  .pb-150 {
    @media screen and (max-width: 767px) {
      padding-bottom: 30px;
    }
  }
}
</style>
