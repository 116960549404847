<template>
  <div class="home-about-us">
    <div class="flex-cover wrap">
      <div class="left">
        <h2>Company<br>Information</h2>
        <p class="sub-title">会社概要</p>
      </div>
      <div class="right">
        <UiTable :table-list="tableList" />
    </div>

    </div>

    <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="100"
      :width="100"
      :button="false"
      class="slider"
    />


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable from "@/components/Ui/Table.vue";
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "home-top-class-website",
  components: {
    UiTable,
    UiSliderBox,
  },
  data() {
    return {
      tableList:[
      { id: 1, th: "企業名", td: "株式会社 グレイトヘルプ" },
        { id: 2, th: "代表者名", td: "小関 貴久" },
        { id: 3, th: "本社<br class=sp-only>所在地", td: "岐阜県岐阜市加納桜田町2-5-2" },
        { id: 4, th: "電話番号", td: "058-214-9005" },
        { id: 5, th: "資本金", td: "1,000万円" },
        { id: 6, th: "創業", td: "2012年9月3日" },
        { id: 7, th: "設立", td: "2014年9月3日" },
        { id: 8, th: "従業員数", td: "10名" },
        { id: 9, th: "営業時間", td: "平日9時～18時（土日祝日、年末年始はお休み）" },
      ],
      images: [
        // { id: 1, imgpath: "GH-kyujin-top-007.jpg", name: "会社イメージ1" },
        { id: 1, imgpath: "01.jpg", name: "会社イメージ1" },
        { id: 2, imgpath: "02.jpg", name: "会社イメージ2" },
        { id: 3, imgpath: "03.jpg", name: "会社イメージ3" },
        { id: 4, imgpath: "04.jpg", name: "会社イメージ4" },
        { id: 5, imgpath: "05.jpg", name: "会社イメージ5" },
        { id: 6, imgpath: "06.jpg", name: "会社イメージ6" },
        { id: 7, imgpath: "07.jpg", name: "会社イメージ7" },
        { id: 8, imgpath: "08.jpg", name: "会社イメージ8" },
        { id: 9, imgpath: "09.jpg", name: "会社イメージ9" },
      ],
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 70,
        speed: 1000,
        effect: "slide",
        loop: true,
        centeredSlides : true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    if  (window.matchMedia("(min-width: 1150px)").matches) {
      // default
      // alert(1);
    } else if (window.matchMedia("(min-width: 767px)").matches) {
      // tablet
      // alert(2);
      this.swiperOption.slidesPerView = "3";
      this.swiperOption.spaceBetween = 20;
    }else {
      // sp
      // alert(0);
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1.3";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 20;
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-cover {

  .left {
    width: 50%;
    @media screen and (max-width: 767px){
      width: 100%;
    }
  }
  .right {
    width: 45%;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 30px;
    }
  }
}
.slider{
margin-top: 100px;
}
</style>
